import React from 'react';
import {Card, CardTitle, Container, Col, Row, Button} from 'reactstrap';
import CopyButton from './CopyButton.js'

class BrailleFont extends React.Component{

    constructor(props){
        super(props)

        this.hideText = this.hideText.bind(this)
        this.state = {
            hidden: false
        }
    }


    hideText(){
        this.setState({
            hidden: !this.state.hidden
        })
    }

    render(){
        return(
            <Card className="braille-card">
                <Container>
                    <Row>
                        <Col  xs="7" sm="8" md="9">
                            <CardTitle className="card-title">Braille</CardTitle>
                        </Col>
                        <Col xs="5" sm="4" md="3">
                            <CopyButton copyID="brailleFont" copyText={this.props.copyText}/>
                            <Button color="link" className="go-right copy-button text-right no-side-padding no-side-margin" onClick={this.hideText}>{this.state.hidden ? "show" : "hide"}</Button>
                        </Col>
                    </Row>
                </Container>
                {
                    !this.state.hidden &&
                    <p className="card-text" id="brailleFont">{this.props.ascii}</p>
                }

            </Card>

        )
    }
}


export default BrailleFont
