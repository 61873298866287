import React from 'react';
import {Input, Card, Button, Col, Row, Container} from 'reactstrap';

class BrailleInput extends React.Component{

    render(){
        return (
            <Card className="shadow braille-card ">
                <Container>
                    <Row>
                        <Col xs="6"></Col>
                        <Col xs="6">
                            <Button color="link" className="copy-button go-right text-right" onClick={this.props.clearBraille}>clear</Button>
                        </Col>
                    </Row>
                </Container>
                <Input className="braille-area" type="textarea" value={this.props.text} onChange={(e) => this.props.brailleChanged(e.target.value)} placeholder="Type text here..."/>
            </Card>

        )

    }
}

export default BrailleInput
