import React from 'react';
import {Container, Row, Col} from 'reactstrap';


class Header extends React.Component{


    render(){
        return(
            <div>
                <Container>
                    <Row>
                        <Col xs="1" md="2"></Col>
                        <Col xs="10" md="8">
                            <div className="header-title">
                                <h3 className="large-header text-center asante-text">Asante Sign Group's Braille Translator</h3>
                            </div>
                        </Col>
                        <Col xs="1" md="2"></Col>
                    </Row>

                </Container>

            </div>

        )

    }
}

export default Header
