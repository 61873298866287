import React, {Component} from 'react';
import {Container, Col, Row, Alert} from 'reactstrap';
import BrailleInput from './BrailleInput.js'
import BrailleFont from './BrailleFont.js'
import BrailleAscii from './BrailleAscii.js'
import translator from '../model/translator.js'
import BrailleGraph from './BrailleGraph.js';


class BrailleArea extends Component{

    constructor(props){
        super(props)

        this.brailleChanged = this.brailleChanged.bind(this)
        this.clearBraille = this.clearBraille.bind(this)
        this.hideAlert = this.hideAlert.bind(this)
        this.copyText = this.copyText.bind(this)

        this.state = {
            text: "",
            ascii: "",
            dotSource: "",
            showAlert: false
        }
    }

    brailleChanged(text){

        let asciiEncoding = translator.translate(text);
        const dotSource = translator.getDotSource(text);
        this.setState({text: text, ascii: asciiEncoding, dotSource})
    }

    clearBraille(){

        this.brailleChanged("");
    }

    copyText(){
        let copyText = this.state.ascii

        // Copy Adjustments
        let regex = new RegExp("&amp;", "g")
        copyText = copyText.replace(regex, "&")

        let regex2 = new RegExp("&gt;", "g")
        copyText = copyText.replace(regex2, ">")

        let regex3 = new RegExp("&lt;", "g")
        copyText = copyText.replace(regex3, "<")

        // perform copy
        const el = document.createElement('textarea');
        el.value = copyText;
        el.style.display = 'hidden';
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el)
        this.setState({showAlert: true})

        setTimeout(this.hideAlert, 3000)
    }

    hideAlert(){

        this.setState({
            showAlert: false
        })
    }

    render(){
        return(
            <div>
                <Container>
                    <Row>
                        <Col xs="1" md="2"></Col>
                        <Col xs="10" md="8">
                            <BrailleInput text={this.state.text} brailleChanged={this.brailleChanged} clearBraille={this.clearBraille}/>
                            <BrailleFont ascii={this.state.ascii} copyText={this.copyText}/>
                            <BrailleAscii ascii={this.state.ascii} copyText={this.copyText}/>
                            <BrailleGraph dotSource={this.state.dotSource} />
                        </Col>
                        <Col xs="1" md="2"></Col>
                    </Row>
                </Container>
                <div className="alert-container">
                    <Alert className="text-center asante-alert" color={navigator.userAgent.indexOf("Mobile") === -1 ? "success" : "danger"} isOpen={this.state.showAlert} toggle={() => this.setState({showAlert: false})}>
                        {navigator.userAgent.indexOf("Mobile") === -1 ? "Text Successfully copied!" : "Can't copy text on mobile devices."}
                    </Alert>
                </div>
            </div>
        )
    }
}

export default BrailleArea
