import React, { Component } from 'react';
import Wrapper from './components/Wrapper.js'
import ReactGA from 'react-ga';
import './App.css';
require("es6-promise");


// Analytics Tracking
if(process.env.NODE_ENV === 'production'){

    ReactGA.initialize('UA-131906412-1')
    ReactGA.pageview('/home')
}


class App extends Component {
  render() {
    return (
      <Wrapper />
    );
  }
}

export default App;
