import React from 'react';
import {Container, Col, Row} from 'reactstrap';

class Footer extends React.Component{


    render(){
        return(
            <Container>
                <Row>
                    <Col xs="1" md="2"></Col>
                    <Col xs="10" md="8">
                        <div className="footer">
                            <p className="sub-header text-justify sub-color">
                                This braille translator is specific to ADA Signage and therefore it may handle something such as capitalization different from another braille translator. Braille translation is a difficult task for a machine as things such as context must be taken into account. Therefore, Asante does not guarantee that all braille translations are 100% correct. The translations are made according to the Unified English Braille standard.
                            </p>
                            <p className="text-center">Order Unfinished Thermoform at: <a href="https://www.asgpurchase.com" target="_blank" rel="noopener noreferrer">www.asgpurchase.com</a></p>
                            <p className="text-center">For More Information About Asante: <a href="http://asantesigngroup.com" target="_blank" rel="noopener noreferrer">www.asantesigngroup.com</a></p>
                        </div>
                    </Col>
                    <Col xs="1" md="2"></Col>

                </Row>
            </Container>

        )

    }
}

export default Footer
