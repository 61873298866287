import React, {Component} from 'react';
import BrailleArea from './BrailleArea.js'
import Header from './Header.js'
import Footer from './Footer.js'

class Wrapper extends Component{

    render(){
        return(
            <div>
                <Header />
                <BrailleArea/>
                <Footer />
            </div>
        )
    }
}

export default Wrapper
