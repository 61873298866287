import React from 'react';
import {Button} from 'reactstrap';


class CopyButton extends React.Component{

    render(){
        return(
            <div className="">
                <Button color="link" className="go-right copy-button text-right no-side-padding no-side-margin" onClick={this.props.copyText}>copy</Button>
            </div>

        )
    }
}

export default CopyButton
