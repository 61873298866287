import React from 'react'
import { Graphviz } from 'graphviz-react';
import {Card, CardTitle, Container, Row, Col, Button} from 'reactstrap'
import debounceRender from 'react-debounce-render';


class BrailleGraph extends React.Component{

    constructor(props){
        super(props)
        
        this.hideText = this.hideText.bind(this)
        this.state = {
            hidden: false,
        }
    }

    hideText(){
        this.setState({
            hidden: !this.state.hidden
        })
    }

    render(){
        return(
            <Card className="braille-card">
                <Container>
                    <Row>
                        <Col xs="7" sm="8" md="9">
                            <CardTitle className="card-title">Graph</CardTitle>
                        </Col>
                        <Col xs="5" sm="4" md="3">
                            <Button color="link" className="go-right copy-button text-right no-side-padding no-side-margin" onClick={this.hideText}>{this.state.hidden ? "show" : "hide"}</Button>
                        </Col>
                    </Row>
                </Container>
                {
                    !this.state.hidden && this.props.dotSource &&
                    <Graphviz dot={this.props.dotSource} options={{height: 200, width: "100%", zoom: true}}/>
                }

            </Card>
        )
    }
}

// Graphviz takes awhile to render so put it behind a debounce
export default debounceRender(BrailleGraph, 500);
